import React from "react";
import { useParams } from "react-router-dom";
import GraphDanzigerTS from "../../components/plots/PlotDanziger";
// import Spinner from '../components/layout/Spinner'

function PlotScreenDanz() {
  const { duration } = useParams();
  const today = new Date();
  const start = new Date(today);

  let days =7
  
  if (duration != null) {
    days = duration
  }


  start.setDate(start.getDate() - days);
  today.setDate(today.getDate() + 1);
  // today.toISOString()
  // yesterday.toISOString()
  // console.log(`today: ${today.toISOString().split('T')[0]} and yesterday: ${yesterday.toISOString().split('T')[0]} 00:00:00`)
  const start_time = start.toISOString().split("T")[0] + " 00:00:00";
  const end_time = today.toISOString().split("T")[0] + " 00:00:00";
  // console.log(end_time)
  // const start_time = '2022-02-21 00:00:00'
  // const end_time = '2022-02-26 00:00:00'

  return (
    <div>
      <h2 className="bg-slate-300 text-2xl">Vertical lasers</h2>
      <h6 className="bg-slate-300 text-xs">Displayed interval 1 min.</h6>

      <GraphDanzigerTS
        gagelist={{
          primary: [
            { gage: "vnw_med", name: "VNW", table: "Dan501" ,scalar: 0.0833},
            { gage: "vsw_med", name: "VSW", table: "Dan601", scalar: 0.0833 },
          ],
          secondary: [
            {
              gage: "temp_ambient_avg",
              name: "tempshort",
              table: "Dan602",
              tare: 0,
            },
          ],
          y_range: [0, 70],
          title: "West Vertical",
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [-0.5, 80.],
          y_axis_label: "Vertical Ht. (ft.)",
          y_axis_label2: "Temperature (&#176;F)"
        }}
      />

      <GraphDanzigerTS
        gagelist={{
          primary: [
            { gage: "vne_med", name: "VNE", table: "Dan502", scalar: 0.0833 },
            { gage: "vse_med", name: "VSE", table: "Dan602", scalar: 0.0833 },
          ],
          secondary: [
            {
              gage: "temp_ambient_avg",
              name: "Temp Amb",
              table: "Dan602",
              tare: 0,
            },
          ],
          y_range: [0, 70],
          title: "East Vertical",
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [-0.5, 80.0],
          y_axis_label: "Vertical Ht. (ft.)",
          y_axis_label2: "Temperature (&#176;F)"
        }}
      />

      <h2 className="bg-slate-300 text-2xl">Horizontal lasers</h2>
      <h6 className="bg-slate-300 text-xs">Displayed interval 1 min.</h6>
      <GraphDanzigerTS
        gagelist={{
          primary: [
            { gage: "tnw_med",
             name: "TNW",
              table: "Dan501" 
            },
            { gage: "tsw_med",
             name: "TSW",
              table: "Dan601",
               tare: 0 
              },
            {
              gage: "lnw_ultra_in_med",
              name: "LNW",
              table: "Dan601",
              tare: 20.2,
            },
            {
              gage: "lsw_ultra_in_med",
              name: "LSW",
              table: "Dan601",
              tare: 15.2,
            },
          ],
          secondary: [
            {
              gage: "temp_ambient_avg",
              name: "Temp Amb",
              table: "Dan602",
              tare: 0,
              scalar: 1,
            },
          ],
          y_range: [0, 70],
          title: "West Horizontal",
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [-2.0, 2.0],
          y_axis_label: "Displacement (in.)",
          y_axis_label2: "Temperature (&#176;F)"
        }}
      />

      <GraphDanzigerTS
        gagelist={{
          primary: [
            {
              gage: "tne_ultra_in_med",
              name: "TNE",
              table: "Dan602",
              tare: 15.0,
            },
            {
              gage: "tse_ultra_in_med",
              name: "TSE",
              table: "Dan602",
              tare: 17.0,
            },
            {
              gage: "lne_ultra_in_med",
              name: "LNE",
              table: "Dan602",
              tare: 18.0,
            },
            {
              gage: "lse_ultra_in_med",
              name: "LSE",
              table: "Dan602",
              tare: "17.0",
            },

          ],
          secondary: [
            {
              gage: "temp_ambient_avg",
              name: "Temp Amb",
              table: "Dan602",
              tare: 0,
              scalar: 1,
            },
          ],
          y_range: [0, 70],
          title: "East Horizontal",
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [-1.0, 1.0],
          y_axis_label: "Displacement (in.)",
          y_axis_label2: "Temperature (&#176;F)"
        }}
      />

      <h2 className="bg-slate-300 text-2xl">Shoe Movement</h2>
      <h6 className="bg-slate-300 text-xs">Displayed interval 1 min.</h6>
      <GraphDanzigerTS
        gagelist={{
          primary: [
            { gage: "nw_shoe_avg", name: "nw_shoe", table: "Dan501" },
            {
              gage: "sw_shoe_avg",
              name: "sw_shoe",
              table: "Dan501",
              tare: 0,
            },
          ],
          y_range: [0, 70],
          title: "West Shoes",
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [-0.25, .25],
          y_axis_label: "Displacement (in.)",
        }}
      />
      <GraphDanzigerTS
        gagelist={{
          primary: [
            { gage: "ne_shoe_avg", name: "ne_shoe", table: "Dan502" },
            {
              gage: "se_shoe_avg",
              name: "se_shoe",
              table: "Dan502",
              tare: 0,
            },
          ],
          y_range: [0, 70],
          title: "East Shoes",
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [-3, 3],
          y_axis_label: "Displacement (in.)",
        }}
      />

<h2 className="bg-slate-300 text-2xl">Joint Movement</h2>
<h6 className="bg-slate-300 text-xs">Displayed interval 1 min.</h6>
      <GraphDanzigerTS
        gagelist={{
          primary: [
            { gage: "w_joint_laser_avg",
             name: "w_joint_laser",
              table: "Dan501" },
            {
              gage: "nw",
              name: "nw_corner",
              table: "ultra",
              tare: 14,
              
            },
            {
              gage: "sw",
              name: "sw_corner",
              table: "ultra",
              tare: 17,
              
            },
          ],
          secondary: [
            {
              gage: "temp_ambient_avg",
              name: "Temp.Amb.",
              table: "Dan602",
              tare: 0,
            },
          ],
          y_range: [0, 70],
          title: "West Joint",
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [-3, 3],
          y_axis_label: "Displacement (in.)",
          y_axis_label2: "Temperature (&#176;F)"
        }}
      />
      <GraphDanzigerTS
        gagelist={{
          primary: [
            { gage: "e_joint_laser_avg",
             name: "e_joint_laser",
              table: "Dan502" },
              {
                gage: "se",
                name: "se_corner",
                table: "ultra",
                tare: 13,
                
              },
              {
                gage: "ne",
                name: "ne_corner",
                table: "ultra",
                tare: 16,
                
              },
            
          ],
          secondary: [
            {
              gage: "temp_ambient_avg",
              name: "Temp.Amb.",
              table: "Dan602",
              tare: 0,
            },
          ],
          y_range: [0, 70],
          title: "East Joint",
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [-3, 3],
          y_axis_label: "Displacement (in.)",
          y_axis_label2: "Temperature (&#176;F)"
        }}
      />

      <h2 className="bg-slate-300 text-2xl">Tilts Movement</h2>
      <h6 className="bg-slate-300 text-xs">Displayed interval 1 min.</h6>
      <GraphDanzigerTS
        gagelist={{
          primary: [
            {
              gage: "tilt_about_ns_med",
              name: "Tilt_NS",
              table: "Dan501",
              smooth: 10,
            },
            {
              gage: "tilt_about_ew_med",
              name: "Tilt_EW",
              table: "Dan501",
              tare: 0,
            },
          ],
          secondary: [
            {
              gage: "temp_ambient_avg",
              name: "Temp.Amb.",
              table: "Dan602",
              tare: 0,
            },
          ],
          y_range: [0, 70],
          title: "West End Tilts",
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [-1.0, 1.0],
          y_axis_label: "Displacement (in.)",
          y_axis_label2: "Temperature (&#176;F)"
        }}
      />
      <GraphDanzigerTS
        gagelist={{
          primary: [
            {
              gage: "tilt_about_ns_med",
              name: "Tilt_NS",
              table: "Dan502",
              smooth: 10,
            },
            {
              gage: "tilt_about_ew_med",
              name: "Tilt_EW",
              table: "Dan502",
              tare: 0,
            },
          ],
          secondary: [
            {
              gage: "temp_ambient_avg",
              name: "Temp.Amb.",
              table: "Dan602",
              tare: 0,
            },
          ],
          y_range: [0, 70],
          title: "East End Tilts",
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [-1.0, 1.0],
          y_axis_label: "Displacement (in.)",
          y_axis_label2: "Temperature (&#176;F)"
        }}
      />

<h2 className="bg-slate-300 text-2xl">Shaft Strain</h2>
<h6 className="bg-slate-300 text-xs">Displayed interval 1 hour</h6>
      <GraphDanzigerTS
        gagelist={{
          primary: [
            {
              gage: "nw",
              name: "nw",
              table: "resensys_1H_median",
            },
            {
              gage: "sw",
              name: "sw",
              table: "resensys_1H_median",
              tare: 0,
            },
          ],

          y_range: [0, 70],
          title: "West Shaft Strain",
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{

          y_axis_label: "Microstrain",
        }}
      />
      <GraphDanzigerTS
        gagelist={{
          primary: [
            {
              gage: "ne",
              name: "ne",
              table: "resensys_1H_median",

            },
            {
              gage: "se",
              name: "se",
              table: "resensys_1H_median",
              tare: 0,
            },
          ],
          y_range: [0, 70],
          title: "East Shaft Strain",
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{  y_axis_label: "Microstrain" }}
      />

<h2 className="bg-slate-300 text-2xl">Weather Data</h2>

      <h6 className="bg-slate-300 text-xs">Displayed interval 1 min.</h6>
      <GraphDanzigerTS
        gagelist={{
          primary: [
            {
              gage: "ws_mph_avg",
              name: "Windspeed",
              table: "cr1000x",

            },

          ],
          secondary: [
            {
              gage: "winddir",
              name: "Wind direction",
              table: "cr1000x",
              tare: 0,
            },
          ],
          y_range: [0, 70],
          title: "Wind",
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [-1.0, 60.0],
          y_axis_label: "Windspeed (mph)",
          y_axis_label2: "Wind direction (deg.)"
        }}
      />
      <GraphDanzigerTS
        gagelist={{
          primary: [
            {
              gage: "airtf_avg",
              name: "Temp Amb Tower",
              table: "cr1000x",

            },
            {
              gage: "temp_ambient_avg",
              name: "Temp Amb Deck",
              table: "Dan602",

            },
            {
              gage: "temp_top_avg",
              name: "Temp Deck Top",
              table: "Dan601",

            },
            {
              gage: "temp_bottom_avg",
              name: "Temp Deck Bottom",
              table: "Dan601",

            },
          ],
          secondary: [
            {
              gage: "rh_max",
              name: "Relative Humidity",
              table: "cr1000x",
              tare: 0,
            },
          ],
          y_range: [0, 120],
          title: "Temperature & Humidity",
        }}
        dateRange={{ dateRange: [start_time, end_time] }}
        config={{
          y_axes_range: [-1.0, 120.0],
          y_axis_label: "Temperature (&#176;F)",
          y_axis_label2: "Relative Humidity (%)"
        }}
      />
    </div>
  );
}

export default PlotScreenDanz;
