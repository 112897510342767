import React, { useRef, useEffect, useState } from 'react'
// import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css'
const mapboxToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

function Mapbox({ location, markers }) {
  console.log(mapboxToken)
  mapboxgl.accessToken =mapboxToken 



  console.log(markers)
  const mapContainer = useRef(null)
  const map = useRef(null)
  // const [lng, setLng] = useState(-90.027748)
  // const [lat, setLat] = useState(30.008149)
  const [lng, setLng] = useState(location[0])
  const [lat, setLat] = useState(location[1])
  const [zoom, setZoom] = useState(17.5)

  useEffect(() => {
    if (map.current) return // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/satellite-v9',
      center: [lng, lat],
      zoom: zoom,
    })
    //const marker = new mapboxgl.Marker()

    // for (const item in markers) {
    //   console.log(item)
    //   // marker
    //   // .setLngLat(item)
    //   // .addTo(map.current);
    // }

    // markers.forEach(function (item) {
    //   console.log(item)
    //   marker.setLngLat(item).addTo(map.current)
    // })

    // add markers to map
    for (const feature of markers.features) {
      // create a HTML element for each feature
      const el = document.createElement('div')
      el.className = 'marker'

      // make a marker for each feature and add to the map
      new mapboxgl.Marker(el).setLngLat(feature.geometry.coordinates).addTo(map.current)
    }

    // map.current.addSource("points", {
    //   type: "geojson",
    //   data: {
    //     type: "geojson",
    //     features: geojson.features,
    //   }})

    // map.addLayer({
    //     id: "points",
    //     type: "symbol",
    //     source: "points",})

    // const marker0 = new mapboxgl.Marker()
    // .setLngLat([-90.027569, 30.008166])
    // .addTo(map.current);

    // const marker1 = new mapboxgl.Marker()
    // .setLngLat([-90.027748, 30.008149])
    // .addTo(map.current);

    // const marker2 = new mapboxgl.Marker()
    // .setLngLat([-90.028134, 30.008056])
    // .addTo(map.current);
  })

  // useEffect(() => {
  //   if (!map.current) return // wait for map to initialize
  //   map.current.on('move', () => {
  //     setLng(map.current.getCenter().lng.toFixed(4))
  //     setLat(map.current.getCenter().lat.toFixed(4))
  //     setZoom(map.current.getZoom().toFixed(2))
  //   })
  // })

  return (
    <div>
      <div className='sidebar'>
        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
      </div>
      <div ref={mapContainer} className='map-container' />
    </div>
  )
}

export default Mapbox
