import { render } from '@testing-library/react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { FaCheck, FaTimes } from 'react-icons/fa'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  solid,
  regular,
  brands,
} from '@fortawesome/fontawesome-svg-core/import.macro'
import dayjs from 'dayjs'

const REACT_DJANGO = process.env.REACT_APP_DJANGO

let url = REACT_DJANGO + '/api/nyc/status/'

const NY17Status = (eventtime) => {
  // const { eventtime } = useParams()
  const [data, setData] = useState({})
  const [event, setEvent] = useState({})
  const [isLoading, setLoading] = useState(true)

  // console.log(eventtime.eventtime)

  // console.log(JSON.stringify({'dateRange':[start_time,end_time]}))
  useEffect(() => {
    const getStatus = async () => {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          // 'Authorization':'Bearer '+this.token
        },
      })

      const resopnseJson = await response.json()
      console.log('json', resopnseJson.status_ny17.pk)
      setData(resopnseJson)
      setLoading(false)
    }
    getStatus()
  }, [])

  if (isLoading) {
    return <div>loading</div>
  } else {
    return (
      <div className='flex flex-col'>
        <div className='overflow-x-auto sm:-mx-6 lg:-mx-8'>
          <div className='py-2 inline-block min-w-full sm:px-6 lg:px-8'>
            <div className='overflow-hidden'>
              <h2>Status</h2>
              <table>
                <thead className='bg-white border-b'>
                  <tr>
                    <th
                      scope='col'
                      className='text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap'
                    >
                      System
                    </th>
                    <th
                      scope='col'
                      className='text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap'
                    >
                      Last Data Time
                    </th>
                  </tr>
                  <tr>
                    <td
                      scope='col'
                      className='text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap'
                    >
                      NY17 Datalogger
                    </td>
                    <td
                      scope='col'
                      className='text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap'
                    >
                      {data.status_ny17.pk}
                    </td>
                  </tr>


                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NY17Status
