import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Mapbox from './pages/Mapbox'
import DanzigerMap from './pages/Danziger/DanzigerMap'
import About from './pages/About'
import User from './pages/User'
import Login from './pages/LoginPage'
import PlotScreenNY17 from './pages/PlotScreenNY17'
import PlotScreenLuling from './pages/Luling/PlotScreenLuling'
import PlotScreenDanz from './pages/Danziger/PlotScreenDanz'
import PlotScreenDanzEvent from './pages/Danziger/PlotScreenDanzEvent'
import DanzigerCameraScreen from './pages/Danziger/DanzigerCameraScreen'
import ListLifts from './pages/Danziger/ListLiftsDanziger'
import BearingData from './pages/Danziger/BearingsDanziger'
import PlotScreenSeismo from './pages/seismo/PlotScreenSeismo'
import PlotScreenSeismoWestview from './pages/seismo/PlotScreenSeismoWestview'
import PlotScreenSeismoNIH from './pages/seismo/PlotScreenSeismoNIH'

import PlotScreenWmata from './pages/WMATA/PlotScreenWmata'
import Weather from './pages/Weather'
import NotFound from './pages/NotFound'
import NavbarBoot from './components/layout/NavbarNew'
import Footer from './components/layout/Footer'
import Alert from './components/layout/Alert'
import {GithubProvider} from './context/github/GithubContext'
import {AlertProvider} from './context/alert/AlertContext'
import {UserProvider} from './context/user/UserContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import StatusView from './pages/StatusView'



function App() {
  return (
    <GithubProvider>
      <AlertProvider>
        <UserProvider>
      <Router>
      <div className="flex flex-col justify-between h-screen">
        <NavbarBoot />

        <main className='container mx-auto px-3 pb-12'>
          <Alert />
          <Routes>
            <Route path='/' element={<Login/>  }/>

            <Route path='/luling' element={<PlotScreenLuling/> }/>
            <Route path='/luling/:duration' element={<PlotScreenLuling/> }/>
            <Route path='/danziger' element={<PlotScreenDanz/> }/>
            <Route path='/bearings/:eventtime' element={<BearingData/> }/>
            <Route path='/danziger/:duration' element={<PlotScreenDanz/> }/>
            <Route path='/wmata' element={<PlotScreenWmata/> }/>
            <Route path='/wmata/:duration' element={<PlotScreenWmata/> }/>
            <Route path='/danzCamera' element={<DanzigerCameraScreen/> }/>
            <Route path='/danzEvent' element={<PlotScreenDanzEvent/> }/>
            <Route path='/danzEvent/:eventtime' element={<PlotScreenDanzEvent/> }/>
            <Route path='/danzLifts' element={<ListLifts/> }/>

            <Route path='/seismo' element={<PlotScreenSeismo/> }/>
            <Route path='/westview' element={<PlotScreenSeismoWestview/> }/>
            <Route path='/n40a' element={<PlotScreenSeismoNIH/> }/> 

            <Route path='/ny17' element={<PlotScreenNY17/> }/>
            <Route path='/status' element={<StatusView/> }/>
            
            <Route path='/weather' element={<Weather/> }/>
            <Route path='/about' element={<About/> }/>
            <Route path='/user/:login' element={<User/> }/> 
            <Route path='/login' element={<Login/> }/>     
            <Route path='/danzigermap' element={<DanzigerMap/> }/>
            <Route path='/notfound' element={<NotFound/> }/>
            <Route path='/*' element={<NotFound/> }/>
          </Routes>
        </main>
        <Footer />
      </div>

    </Router>
    </UserProvider>
      </AlertProvider>

    </GithubProvider>
  );
}

export default App;
