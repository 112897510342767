import { render } from '@testing-library/react';
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { FaCheck,FaTimes } from 'react-icons/fa'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import dayjs from 'dayjs';

const REACT_DJANGO = process.env.REACT_APP_DJANGO

let url = REACT_DJANGO +'/api/danziger/get_bearings_event/'


const BearingData = (eventtime)=>{
  // const { eventtime } = useParams()
  const [data,setData] = useState({})
  const [event,setEvent] = useState({})
  const [isLoading, setLoading] = useState(true);

  let datajson =[]

  // console.log(eventtime.eventtime)
  const eventTS = new Date(eventtime.eventtime)
  // console.log(eventTS)
  // const start = new Date(eventTS.setMinutes(eventTS.getMinutes() - 5 - 4 * 60))
  // const start_time = eventTS.toISOString().slice(0, -1)
  // const end_time = new Date(eventTS.setMinutes(eventTS.getMinutes() + 10)).toISOString().slice(0, -1)

  const start_time = dayjs(eventtime.eventtime).add(-5,'minute').format('YYYY-MM-DDTHH:mm:ss').toString()
  const end_time = dayjs(eventtime.eventtime).add(10,'minute').format('YYYY-MM-DDTHH:mm:ss').toString()

  useEffect(() =>{

  const getBearingData = async () =>{

  const response = await fetch(url,{
    method:'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      // 'Authorization':'Bearer '+this.token
    },
    body: JSON.stringify({'dateRange':[start_time,end_time]})
})

    const resopnseJson = await response.json();
    //console.log("json", JSON.parse(resopnseJson));
    datajson = JSON.parse(resopnseJson)
    // console.log(`data: ${datajson.maximum[0]}`)
    // console.log(datajson)


    if(typeof datajson.maximum[0] == 'undefined' ||typeof datajson.maximum[1] == 'undefined'){
      setLoading(true);
      console.log('error')
    } else{
      setData(datajson);
      setLoading(false);
    }

  }
  getBearingData()
  
},[])
  
  

  if (isLoading) {
    return (
      <div>Loading or No data available on West

    </div>
    )
  } else {

    return (
      <div className="flex flex-col pr-0.5">
 
        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
            <h2 className="bg-slate-200 text-xl">UP</h2>
              <h3 className="text-xl">SE_OB</h3>

              <table>

                  <thead className='bg-white border-b'>
                    <tr>
                      <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Band</th>
                      <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_0</th>
                      <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_1</th>
                      <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_2</th>
                      <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_3</th>
                      <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Max</th>
                    </tr>
                    <tr>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">A</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_0_banda[0].toFixed(1)}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_1_banda[0].toFixed(1)}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_2_banda[0].toFixed(1)}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_3_banda[0].toFixed(1)}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_0_banda[0],data.sensor_1_banda[0],data.sensor_2_banda[0],data.sensor_3_banda[0]).toFixed(1)}</td>
                    </tr>
                    <tr>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">B</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_0_bandb[0].toFixed(1)}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_1_bandb[0].toFixed(1)}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_2_bandb[0].toFixed(1)}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_3_bandb[0].toFixed(1)}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_0_bandb[0],data.sensor_1_bandb[0],data.sensor_2_bandb[0],data.sensor_3_bandb[0]).toFixed(1)}</td>
                    </tr>
                    <tr>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">C</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_0_bandc[0].toFixed(1)}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_1_bandc[0].toFixed(1)}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_2_bandc[0].toFixed(1)}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_3_bandc[0].toFixed(1)}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_0_bandb[0],data.sensor_1_bandc[0],data.sensor_2_bandc[0],data.sensor_3_bandc[0]).toFixed(1)}</td>
                    </tr>
                  </thead>
              </table>
              <h3 className="text-xl">SW_IB</h3>

<table>

    <thead className='bg-white border-b'>
      <tr>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Band</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_4</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_5</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_6</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_7</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Max</th>
      </tr>
      <tr>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">A</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_4_banda[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_5_banda[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_6_banda[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_7_banda[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_4_banda[0],data.sensor_5_banda[0],data.sensor_6_banda[0],data.sensor_7_banda[0]).toFixed(1)}</td>
      </tr>
      <tr>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">B</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_4_bandb[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_5_bandb[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_6_bandb[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_7_bandb[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_4_bandb[0],data.sensor_5_bandb[0],data.sensor_6_bandb[0],data.sensor_7_bandb[0]).toFixed(1)}</td>
      </tr>
      <tr>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">C</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_4_bandc[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_5_bandc[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_6_bandc[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_7_bandc[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_4_bandc[0],data.sensor_5_bandc[0],data.sensor_6_bandc[0],data.sensor_7_bandb[0]).toFixed(1)}</td>
      </tr>
    </thead>
</table>

<h3 className="text-xl">NW_IB</h3>

<table>

    <thead className='bg-white border-b'>
      <tr>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Band</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_8</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_9</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_10</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_11</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Max</th>
      </tr>
      <tr>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">A</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_8_banda[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_9_banda[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_10_banda[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_11_banda[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_8_banda[0],data.sensor_9_banda[0],data.sensor_10_banda[0],data.sensor_11_banda[0]).toFixed(1)}</td>
      </tr>
      <tr>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">B</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_8_bandb[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_9_bandb[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_10_bandb[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_11_bandb[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_8_bandb[0],data.sensor_9_bandb[0],data.sensor_10_bandb[0],data.sensor_11_bandb[0]).toFixed(1)}</td>
      </tr>
      <tr>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">C</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_8_bandc[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_9_bandc[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_10_bandc[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_11_bandc[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_8_bandc[0],data.sensor_9_bandc[0],data.sensor_10_bandc[0],data.sensor_11_bandc[0]).toFixed(1)}</td>
      </tr>
    </thead>
</table>

<h3 className="text-xl">NW_OB</h3>

<table>

    <thead className='bg-white border-b'>
      <tr>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Band</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_12</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_13</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_14</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_15</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Max</th>
      </tr>
      <tr>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">A</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_12_banda[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_13_banda[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_14_banda[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_15_banda[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_12_banda[0],data.sensor_13_banda[0],data.sensor_14_banda[0],data.sensor_15_banda[0]).toFixed(1)}</td>
      </tr>
      <tr>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">B</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_12_bandb[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_13_bandb[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_14_bandb[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_15_bandb[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_12_bandb[0],data.sensor_13_bandb[0],data.sensor_14_bandb[0],data.sensor_15_bandb[0]).toFixed(1)}</td>
      </tr>
      <tr>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">C</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_12_bandc[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_13_bandc[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_14_bandc[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_15_bandc[0].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_12_bandc[0],data.sensor_13_bandc[0],data.sensor_14_bandc[0],data.sensor_15_bandc[0]).toFixed(1)}</td>
      </tr>
    </thead>
</table>
            <h2 className="bg-slate-200 text-xl">DOWN</h2>
              <h3 className="text-xl">SE_OB</h3>

              <table>

                  <thead className='bg-white border-b'>
                    <tr>
                      <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Band</th>
                      <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_0</th>
                      <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_1</th>
                      <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_2</th>
                      <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_3</th>
                      <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Max</th>
                    </tr>
                    <tr>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">A</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_0_banda[1].toFixed(1)}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_1_banda[1].toFixed(1)}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_2_banda[1].toFixed(1)}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_3_banda[1].toFixed(1)}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_0_banda[1],data.sensor_1_banda[1],data.sensor_2_banda[1],data.sensor_3_banda[1]).toFixed(1)}</td>
                    </tr>
                    <tr>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">B</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_0_bandb[1].toFixed(1)}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_1_bandb[1].toFixed(1)}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_2_bandb[1].toFixed(1)}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_3_bandb[1].toFixed(1)}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_0_bandb[1],data.sensor_1_bandb[1],data.sensor_2_bandb[1],data.sensor_3_bandb[1]).toFixed(1)}</td>
                    </tr>
                    <tr>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">C</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_0_bandc[1].toFixed(1)}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_1_bandc[1].toFixed(1)}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_2_bandc[1].toFixed(1)}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_3_bandc[1].toFixed(1)}</td>
                      <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_0_bandb[1],data.sensor_1_bandc[1],data.sensor_2_bandc[1],data.sensor_3_bandc[1]).toFixed(1)}</td>
                    </tr>
                  </thead>
              </table>
              <h3 className="text-xl">SW_IB</h3>

<table>

    <thead className='bg-white border-b'>
      <tr>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Band</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_4</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_5</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_6</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_7</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Max</th>
      </tr>
      <tr>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">A</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_4_banda[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_5_banda[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_6_banda[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_7_banda[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_4_banda[1],data.sensor_5_banda[1],data.sensor_6_banda[1],data.sensor_7_banda[1]).toFixed(1)}</td>
      </tr>
      <tr>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">B</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_4_bandb[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_5_bandb[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_6_bandb[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_7_bandb[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_4_bandb[1],data.sensor_5_bandb[1],data.sensor_6_bandb[1],data.sensor_7_bandb[1]).toFixed(1)}</td>
      </tr>
      <tr>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">C</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_4_bandc[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_5_bandc[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_6_bandc[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_7_bandc[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_4_bandc[1],data.sensor_5_bandc[1],data.sensor_6_bandc[1],data.sensor_7_bandb[1]).toFixed(1)}</td>
      </tr>
    </thead>
</table>

<h3 className="text-xl">NW_IB</h3>

<table>

    <thead className='bg-white border-b'>
      <tr>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Band</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_8</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_9</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_10</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_11</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Max</th>
      </tr>
      <tr>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">A</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_8_banda[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_9_banda[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_10_banda[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_11_banda[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_8_banda[1],data.sensor_9_banda[1],data.sensor_10_banda[1],data.sensor_11_banda[1]).toFixed(1)}</td>
      </tr>
      <tr>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">B</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_8_bandb[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_9_bandb[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_10_bandb[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_11_bandb[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_8_bandb[1],data.sensor_9_bandb[1],data.sensor_10_bandb[1],data.sensor_11_bandb[1]).toFixed(1)}</td>
      </tr>
      <tr>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">C</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_8_bandc[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_9_bandc[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_10_bandc[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_11_bandc[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_8_bandc[1],data.sensor_9_bandc[1],data.sensor_10_bandc[1],data.sensor_11_bandc[1]).toFixed(1)}</td>
      </tr>
    </thead>
</table>

<h3 className="text-xl">NW_OB</h3>

<table>

    <thead className='bg-white border-b'>
      <tr>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Band</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_12</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_13</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_14</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Sensor_15</th>
        <th scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">Max</th>
      </tr>
      <tr>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">A</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_12_banda[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_13_banda[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_14_banda[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_15_banda[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_12_banda[1],data.sensor_13_banda[1],data.sensor_14_banda[1],data.sensor_15_banda[1]).toFixed(1)}</td>
      </tr>
      <tr>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">B</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_12_bandb[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_13_bandb[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_14_bandb[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_15_bandb[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_12_bandb[1],data.sensor_13_bandb[1],data.sensor_14_bandb[1],data.sensor_15_bandb[1]).toFixed(1)}</td>
      </tr>
      <tr>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">C</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_12_bandc[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_13_bandc[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_14_bandc[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{data.sensor_15_bandc[1].toFixed(1)}</td>
        <td scope="col"className="text-sm text-gray-900 font-light px-4 py-2 whitespace-nowrap">{Math.max(data.sensor_12_bandc[1],data.sensor_13_bandc[1],data.sensor_14_bandc[1],data.sensor_15_bandc[1]).toFixed(1)}</td>
      </tr>
    </thead>
</table>

            </div>
          </div>
        </div>
      </div>
    );

}
}

export default BearingData