import React from 'react'

import GraphSeismoHz from '../components/plots/PlotSeismoHz'
import DanzigerStatus from './Danziger/DanzigerStatus'
import LulingStatus from './LulingStatus'
import NY17Status from './NY17Status'
import N40aStatus from './N40a/N40Status'

function StatusView() {
  // const start_time = '2022-02-21 00:00:00'
  // const end_time = '2022-02-26 00:00:00'

  var userData = JSON.parse(localStorage.getItem('userInfo'))

  var groups=[]

  if (userData !== null) {
    groups = userData.groups
    console.log(groups);
}

  return (
    <>
          <p className='text-lg text-gray-400'>Access Data Pages from banner menu above</p>
    
    {groups.includes('danziger') ?      (<><h2 className='bg-slate-300 text-2xl'>Danziger System Summary</h2>
      <DanzigerStatus /></>): '' }

      {groups.includes('luling') ?      (<><h2 className='bg-slate-300 text-2xl'>Luling System Summary</h2>
      <LulingStatus /></>): '' }

      {groups.includes('ny17') ?      (<><h2 className='bg-slate-300 text-2xl'>NY17 System Summary</h2>
      <NY17Status /></>): '' }


      {groups.includes('n40a') ?      (<><h2 className='bg-slate-300 text-2xl'>N40a System Summary</h2>
      <N40aStatus /></>): '' }

    </>
  )
}

export default StatusView
